<template>
  <v-container class="px-2">
    <h1 class="text-h3 font-weight-bold"> <textloader-component textKey="KarriärRubrik"/> </h1>
    <paragraph-component> <textloader-component textKey="KarriärBrödtext"/> </paragraph-component>
  </v-container>
</template>

<script>
import ParagraphComponent from '@/components/ParagraphComponent'
import TextloaderComponent from '@/components/TextloaderComponent'
export default {
  components: {
    ParagraphComponent,
    TextloaderComponent
  }
}
</script>
